<template>
  <div class="editUserWrapper">
     <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="isSpinner"/>
     <b-card>
      <div class="row">
        <div class="col-md-12">
          <div class="resgisterFormWrapper signupForm">
            <b-form
              @submit="checkFormData"
              v-if="show"
              class="loginForm"
            >
              <!-- <div class="row">
                  <div class="col-md-12">
                    <h3 class="card-title">Edit User</h3>
                  </div>
              </div> -->
              <div class="row">
                <div class="col-md-3">
                  <b-form-group
                    class="userNameFormGroup"
                    label-for="input-1"
                    :description="errors.fName"
                  >
                    <template #label>
                      First Name <span class="errorAasterisk">*</span>
                    </template>
                    <b-form-input
                      class="InputFormClass"
                      v-model.trim="form.fName"
                      type="text"
                      placeholder="Enter first name"
                      autocomplete="off"
                      @input="checkFirstName"
                      maxlength="25"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <b-form-group
                    class="userNameFormGroup"
                    label-for="input-1"
                    :description="errors.lName"
                  >
                    <template #label>
                      Last Name <span class="errorAasterisk">*</span>
                    </template>
                    <b-form-input
                      class="InputFormClass"
                      v-model.trim="form.lName"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter last name"
                      @input="checkLastName"
                      maxlength="25"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <b-form-group
                    class="userNameFormGroup"
                    label-for="input-1"
                    :description="errors.companyName"
                  >
                    <template #label>
                      Company Name <span class="errorAasterisk">*</span>
                    </template>
                    <b-form-input
                      class="InputFormClass"
                      v-model.trim="form.companyName"
                      autocomplete="off"
                      type="text"
                      placeholder="Enter your company name"
                      @input="checkCompanyName"
                      maxlength="100"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <b-form-group
                    class="userNameFormGroup"
                    label-for="input-1"
                    :description="errors.tel"
                  >
                    <template #label>
                      Cell Phone <span class="errorAasterisk">*</span>
                    </template>
                    <b-form-input
                      class="InputFormClass"
                      v-model.trim="form.tel"
                      type="tel"
                      autocomplete="off"
                      placeholder="Enter phone number"
                      @keypress="isValidNumber"
                      @input="checkPhone"
                      maxlength="15"
                      @copy.prevent
                      @paste.prevent
                    ></b-form-input>
                  </b-form-group>
                </div>
            </div>
              <div class="row">
                <div class="col-md-3">
                  <b-form-group
                    class="userNameFormGroup"
                    label-for="input-1"
                    :description="errors.email"
                  >
                    <template #label>
                      Email Id <span class="errorAasterisk">*</span>
                    </template>
                    <b-form-input
                      class="InputFormClass"
                      v-model.trim="form.email"
                      type="email"
                      placeholder="Enter email id"
                      autocomplete="off"
                      @keyup="isValidEmail"
                      maxlength="80"
                      @copy.prevent
                      @paste.prevent
                      disabled
                    ></b-form-input>
                    <!-- <span class="error_message" v-if="errors.email">{{errors.email}}</span> -->
                  </b-form-group>
                </div>
              </div>
              <div class="checkListWrapper signupFormCheckList">
                <!-- <h4>Check That All Apply<span class="errorAasterisk">*</span></h4> -->
                <div class="row checkBoxRow">
                  <!-- <div class="col-md-12">
                    <form>
                      <div class="checkBox">
                        <div class="form-group-chekbox" style="display: flex; flex-wrap: wrap;"> 
                        <div
                          class="col-md-3"
                          v-for="status in checkBox"
                          :key="status.key"
                        >
                        
                          <input
                            type="checkbox"
                            :id="status.key"
                            :value="status.key"
                            v-model="form.checkboxSelected"
                          />
                          <label :for="status.key" class="checkBoxLabel">{{
                            status.displayName
                          }}</label>
                        </div>
                        </div>
                      </div>
                    </form>
                    <span
                      class="error_message"
                      v-if="
                        errors.checkboxSelected &&
                        form.checkboxSelected.length == 0
                      "
                      >{{ errors.checkboxSelected }}</span
                    >
                  </div> -->
                <div class="editUserBtnWrapper">
                  <div
                    class="SignUpWrapper"
                    style="margin-top: 10px; margin-right: 20px"
                  >
                    <b-button
                      type="button"
                      variant="secondary"
                      class="btnCommonColor cancelBtn"
                      :disabled="isSpinner"
                      @click="onCancel()"
                      >Cancel</b-button
                    >
                  </div>
                  <div class="SignUpWrapper" style="margin-top: 10px">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="btnCommonColor submitBtn"
                      :disabled="isSpinner"
                      >Submit</b-button
                    >
                  </div>
                </div>
                  <!-- <div class="alreadyAccountDiv">
                      <p class="alreadyAccountText">Already have an Account? <span class="termColor"><router-link to="/login" :disabled="isSpinner">Login </router-link></span></p>
                  </div> -->
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import firebase from "@/utils/firebaseInit";
const db = firebase.firestore();
import { dbCollections } from "@/utils/firebaseCollections";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SignUp",
  data() {
    return {
      form: {
        email: "",
        fName: "",
        lName: "",
        companyName: "",
        password: "",
        tel: "",
        // checkboxSelected: [],
      },
      isTermsAndCondition: false,
      isSpinner: true,
      errors: {
        email: "",
        fName: "",
        lName: "",
        companyName: "",
        password: "",
        tel: "",
        // checkboxSelected: "",
        termsAndCondition: "",
        number: "",
      },
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      setting: "setting/getSetting",
      idSpecificUserArray : "user/idSpecificUser"
    }),
    // checkBox() {
    //   if (this.setting.length) {
    //     let ind = this.setting.findIndex((data) => {
    //       return data.id == "checkThatAllApply";
    //     });
    //     return this.setting[ind].status;
    //   } else {
    //     return [];
    //   }
    // },
  },
  created() {
    var self = this;
    self.form = {
      email: "",
      fName: "",
      lName: "",
      companyName: "",
      tel: "",
      // checkboxSelected: [],
    }
    self.getSetting()
    self.idSpecificUserData(self.$route.params.id).then(() => {
      self.isSpinner = false;
      if(self.idSpecificUserArray && self.idSpecificUserArray.length){
        self.$root.$emit("UserDetailName", `${self.idSpecificUserArray[0].firstName} ${self.idSpecificUserArray[0].lastName}`);
        // console.log(self.idSpecificUserArray,'idSpecificUserArray');
        self.form = {
          email: self.idSpecificUserArray[0].email,
          fName: self.idSpecificUserArray[0].firstName,
          lName: self.idSpecificUserArray[0].lastName,
          companyName: self.idSpecificUserArray[0].companyName,
          tel: self.idSpecificUserArray[0].phoneNumber,
          // checkboxSelected: self.idSpecificUserArray[0].checkThatAllApply,
        }

      }else{
        self.isSpinner = false;
      }
    });
  },
  methods: {
    ...mapActions({
      getSetting: "setting/getSettingCollection",
      idSpecificUserData:"user/idSpecificUserData"
    }),
    checkFirstName() {
      var self = this;
      if (!this.form.fName || self.form.fName == "") {
        self.errors.fName = "First name is required";
      } else if (self.form.fName.length < 2) {
        self.errors.fName = "First name must be at least 2 characters";
      } else {
        self.errors.fName = "";
      }
    },
    checkLastName() {
      var self = this;
      if (!this.form.lName || self.form.lName == "") {
        self.errors.lName = "Last name is required";
      } else if (self.form.lName.length < 2) {
        self.errors.lName = "Last name must be at least 2 characters";
      } else {
        self.errors.lName = "";
      }
    },
    checkCompanyName() {
      var self = this;
      if (!this.form.companyName || self.form.companyName == "") {
        self.errors.companyName = "Company name is required";
      } else if (self.form.companyName.length < 2) {
        self.errors.companyName = "Company name must be at least 2 characters";
      } else {
        self.errors.companyName = "";
      }
    },
    checkPhone() {
      var self = this;
      if (!self.form.tel || self.form.tel == "") {
        self.errors.tel = "Cell phone  is required";
      } else if (self.form.tel.length < 7) {
        self.errors.tel = "Cell phone  must be at least 7 numbers";
      } else {
        self.errors.tel = "";
      }
    },
    isValidEmail() {
      var self = this;
      if (event.target.value == "") {
        self.errors.email = "Email is required";
      } else {
        var reg =
          /^[A-Za-z0-9 .]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(event.target.value)) {
          self.errors.email = "";
          // return true;
        } else {
          self.errors.email = "Enter valid email";
          // return false;
        }
      }
    },
    isValidNumber(event) {
      if (
        event.code !== "Numpad" + event.key &&
        event.code !== "NumpadDecimal" &&
        event.code !== "Digit" + event.key
      ) {
        event.preventDefault();
      } else {
        true;
      }
    },
    checkFormData(event) {
      try {
        event.preventDefault();
        var self = this;
        let temp = true;
        // self.errors.checkboxSelected = "";
        self.errors.termsAndCondition = "";
        Object.keys(self.errors).forEach((element) => {
          if (self.errors[element] !== "") {
            temp = false;
          }
        });
        if (!self.form.fName || self.form.fName === "") {
          temp = false;
          self.errors.fName = "First name is required ";
        } else if (self.form.fName.length < 2) {
          temp = false;
          self.errors.fName = "First name must be at least 2 characters";
        }
        if (!self.form.lName || self.form.lName === "") {
          temp = false;
          self.errors.lName = "Last name is required ";
        } else if (self.form.lName.length < 2) {
          temp = false;
          self.errors.lName = "Last name must be at least 2 characters";
        }
        if (!self.form.companyName || self.form.companyName === "") {
          temp = false;
          self.errors.companyName = "Company name is required";
        } else if (self.form.companyName.length < 2) {
          temp = false;
          self.errors.companyName =
            "Company name must be at least 2 characters";
        }
        if (!self.form.email || self.form.email === "") {
          temp = false;
          self.errors.email = "Email is required";
        }
        if (!self.form.tel || self.form.tel === "") {
          temp = false;
          self.errors.tel = "Cell phone  required";
        } else if (self.form.tel.length < 2) {
          temp = false;
          self.errors.tel = "Cell phone  must be at least 7 numbers";
        }
        // if (
        //   !self.form.checkboxSelected ||
        //   self.form.checkboxSelected.length == 0
        // ) {
        //   temp = false;
        //   self.errors.checkboxSelected = "Please select at least one";
        // }
        // if(!self.isTermsAndCondition) {
        //   temp = false;
        //   self.errors.termsAndCondition = "Please agree to FixerTrader’s Terms of Use and Privacy Policy"
        // }
        if (temp) {
          self.onSubmit();
        } else {
          console.error("ERROR", self.errors);
        }
      } catch (error) {
        console.error(error);
      }
    },
    onSubmit() {
      try {
        var self = this;
        self.isSpinner = true;
        let updateObject = {
          firstName : self.form.fName,
          lastName : self.form.lName,
          companyName : self.form.companyName,
          phoneNumber : self.form.tel,
          // checkThatAllApply : self.form.checkboxSelected
        }
        // console.log(updateObject,'updateObjectupdateObject',self.$route,params.id);
        updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,self.$route.params.id,updateObject,(res) => {
          console.log(res,'res');
          self.$toast.success("User updated successfully");
          self.isSpinner = false;
          self.$router.push({name:'user-management'});
        })

      } catch (error) {
        console.error(error);
        self.isSpinner = false;
        self.$toast.error("Something went wrong");
      }
    },
    // onSubmit() {
    //   try {
    //     var self = this;
    //     self.isSpinner = true;
    //     axios
    //       .post(process.env.VUE_APP_APIURL + "/api/v1/createUserAuth", {
    //         email: self.form.email.toLowerCase(),
    //         password: self.form.password,
    //       })
    //       .then((apiRes) => {
    //         console.log("apires", apiRes.data);
    //         if (apiRes.data.status) {
    //           if (apiRes.data.id) {
    //             let userObj = {
    //               createdAt: new Date(),
    //               updatedAt: new Date(),
    //               email: self.form.email.toLowerCase(),
    //               firstName: self.form.fName,
    //               lastName: self.form.lName,
    //               companyName: self.form.companyName,
    //               id: apiRes.data.id,
    //               phoneNumber: self.form.tel,
    //               isEmailVerified: false,
    //               isPhoneNumberVerified: true,
    //               checkThatAllApply: self.form.checkboxSelected,
    //               defaultListType: self.$route.query.key
    //                 ? self.$route.query.key
    //                 : "",
    //               profilePicture: "",
    //               status: 1,
    //             };
    //             console.log(userObj, "userObj");
    //             db.collection(dbCollections.USERS)
    //               .doc(apiRes.data.id)
    //               .set(userObj)
    //               .then(() => {
    //                 axios
    //                   .post(
    //                     process.env.VUE_APP_APIURL +
    //                       "/api/v1/sendVerificationEmail",
    //                     {
    //                       uid: apiRes.data.id,
    //                       email: self.form.email.toLowerCase(),
    //                     }
    //                   )
    //                   .then((apiRes) => {
    //                     if (apiRes.data.status) {
    //                       this.$toast.success(
    //                         "You have successfully registered, Please check your mail inbox or spam folder for the confirmation email."
    //                       );
    //                       self.$router.push({ name: "user-management" });
    //                       this.isSpinner = false;
    //                       // self.onReset()
    //                     } else {
    //                       console.error(apiRes.data.statusText);
    //                       this.isSpinner = false;
    //                     }
    //                   })
    //                   .catch((error) => {
    //                     console.error(error);
    //                     this.isSpinner = false;
    //                   });
    //               })
    //               .catch((error) => {
    //                 console.error(error);
    //                 this.isSpinner = false;
    //               });
    //           } else {
    //             this.isSpinner = false;
    //             this.$toast.error("Something went wrong.");
    //           }
    //         } else {
    //           this.isSpinner = false;
    //           this.$toast.error("Something went wrong.");
    //         }
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //         this.isSpinner = false;
    //         this.$toast.error("Something went wrong.");
    //       });
    //   } catch (error) {
    //     console.error(error);
    //     this.isSpinner = false;
    //     this.$toast.error("Something went wrong.");
    //   }
    // },
    // onReset() {
    //   var self = this;
    //   self.form = {
    //     email: self.idSpecificUserArray[0].email,
    //     fName: self.idSpecificUserArray[0].firstName,
    //     lName: self.idSpecificUserArray[0].lastName,
    //     companyName: self.idSpecificUserArray[0].companyName,
    //     tel: self.idSpecificUserArray[0].phoneNumber,
    //     checkboxSelected: self.idSpecificUserArray[0].checkThatAllApply,
    //   }
    //   self.errors = {
    //     email: "",
    //     fName: "",
    //     lName: "",
    //     companyName: "",
    //     password: "",
    //     tel: "",
    //     checkboxSelected: "",
    //     termsAndCondition: "",
    //     number: "",
    //   }
    // },
    onCancel(){
      this.$router.push({name: "user-management"});
    }
  },
};
</script>

<style>
.error_message {
  color: #17D1C6;
  font-size: 14px;
}
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.text-muted{
  color: red !important;
}
</style>